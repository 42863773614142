//React
import React from 'react'

//Packages
import Scrollspy from 'react-scrollspy'

//Icons
import { FaClipboardList } from 'react-icons/fa'
import { MdPhonelinkRing, MdLock } from 'react-icons/md'
import { IoMdListBox, IoIosRocket, IoMdBody } from 'react-icons/io'
import { AiFillSafetyCertificate } from 'react-icons/ai'

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: '',
    }
  }
  componentDidMount() {
    this.setState({ scrolled: 'onboarding' })
  }
  render() {
    return (
      <div class="feature-menu">
        <Scrollspy
          items={[
            'onboarding',
            'certification',
            'evaluation',
            'notifications',
            'reporting',
            'security',
          ]}
          onUpdate={item => {
            this.setState({
              scrolled:
                item && item.getAttribute('id') ? item.getAttribute('id') : '',
            })
          }}
        >
          <li
            class={
              this.state.scrolled && this.state.scrolled === 'onboarding'
                ? 'menu-active bg-purple-4'
                : ''
            }
          >
            <a class="feature-menu-link scroll" href="#onboarding">
              <IoIosRocket
                className="purple-1"
                style={{
                  fontSize: 18,
                  strokeWidth: 1,
                  marginTop: -3,
                }}
              />
              <span class="black-3">Onboarding</span>
            </a>
          </li>
          <li
            class={
              this.state.scrolled && this.state.scrolled === 'certification'
                ? 'menu-active bg-blue-5'
                : ''
            }
          >
            <a class="feature-menu-link scroll" href="#certification">
              <AiFillSafetyCertificate
                className="blue-1"
                style={{
                  fontSize: 18,
                  strokeWidth: 1,
                  marginTop: -3,
                }}
              />
              <span class="black-3">Self-certification</span>
            </a>
          </li>
          <li
            class={
              this.state.scrolled && this.state.scrolled === 'evaluation'
                ? 'menu-active bg-sky-5'
                : ''
            }
          >
            <a class="feature-menu-link scroll" href="#evaluation">
              <IoMdBody
                className="sky-1"
                style={{
                  fontSize: 22,
                  strokeWidth: 1,
                  marginTop: -3,
                  marginLeft: -4,
                }}
              />
              <span class="black-3">Response evaluation</span>
            </a>
          </li>
          <li
            class={
              this.state.scrolled && this.state.scrolled === 'notifications'
                ? 'menu-active bg-green-6'
                : ''
            }
          >
            <a class="feature-menu-link scroll" href="#notifications">
              <MdPhonelinkRing
                className="green-1"
                style={{
                  fontSize: 18,
                  strokeWidth: 1,
                  marginTop: -3,
                }}
              />
              <span class="black-3">Alerts &amp; notifications</span>
            </a>
          </li>
          <li
            class={
              this.state.scrolled && this.state.scrolled === 'reporting'
                ? 'menu-active bg-yellow-5'
                : ''
            }
          >
            <a class="feature-menu-link scroll" href="#reporting">
              <FaClipboardList
                className="yellow-1"
                style={{
                  fontSize: 18,
                  strokeWidth: 1,
                  marginTop: -3,
                  marginLeft: -2,
                }}
              />
              <span class="black-3">Reporting &amp; audit</span>
            </a>
          </li>
          <li
            class={
              this.state.scrolled && this.state.scrolled === 'security'
                ? 'menu-active bg-orange-5'
                : ''
            }
          >
            <a class="feature-menu-link scroll" href="#security">
              <MdLock
                className="orange-1"
                style={{
                  fontSize: 20,
                  strokeWidth: 1,
                  marginTop: -3,
                  marginLeft: -4,
                }}
              />
              <span class="black-3">Security &amp; privacy</span>
            </a>
          </li>
        </Scrollspy>
      </div>
    )
  }
}

export default Snippet
