//React
import React from 'react'

//Gatsby
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

const Snippet = (props) => (
  <StaticQuery
    query={graphql`
      query {
        featureOnboarding: file(
          relativePath: { eq: "feature/onboarding-text-start.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureSurvey: file(
          relativePath: { eq: "feature/screening-survey.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureEvaluation: file(
          relativePath: { eq: "feature/screening-console-evaluate.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureNotification: file(
          relativePath: { eq: "feature/screening-sendgroup.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureSecurity: file(
          relativePath: { eq: "feature/screening-security.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureReporting: file(
          relativePath: { eq: "feature/reporting-assessments.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={(data) => (
      <div
        class={`feature-body row d-flex flex-row align-items-center justify-content-${
          props.direction === 'reverse' ? 'end' : 'start'
        }`}
      >
        {props.direction && props.direction === 'reverse' ? (
          <div class="feature-body-text col-md-7">
            <ul>
              {props.data.map((item, i) => (
                <li>
                  <strong>{item.title}.&nbsp;</strong>
                  {item.body}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        <div class="col-md-5 d-flex flex-row align-items-center justify-content-center">
          {props.context === 'onboarding' ? (
            <Img
              alt={props.imagealt}
              style={{ minWidth: 240 }}
              fixed={data.featureOnboarding.childImageSharp.fixed}
            />
          ) : null}

          {props.context === 'certification' ? (
            <Img
              alt={props.imagealt}
              style={{ minWidth: 240 }}
              fixed={data.featureSurvey.childImageSharp.fixed}
            />
          ) : null}

          {props.context === 'evaluation' ? (
            <Img
              alt={props.imagealt}
              style={{ minWidth: 240 }}
              fixed={data.featureEvaluation.childImageSharp.fixed}
            />
          ) : null}

          {props.context === 'notifications' ? (
            <Img
              alt={props.imagealt}
              style={{ minWidth: 240 }}
              fixed={data.featureNotification.childImageSharp.fixed}
            />
          ) : null}

          {props.context === 'reporting' ? (
            <Img
              alt={props.imagealt}
              style={{ minWidth: 240 }}
              fixed={data.featureReporting.childImageSharp.fixed}
            />
          ) : null}
          {props.context === 'security' ? (
            <Img
              alt={props.imagealt}
              style={{ minWidth: 240 }}
              fixed={data.featureSecurity.childImageSharp.fixed}
            />
          ) : null}
        </div>
        {!props.direction ? (
          <div class="feature-body-text col-md-7">
            <ul>
              {props.data.map((item, i) => (
                <li>
                  <strong>{item.title}.&nbsp;</strong>
                  {item.body}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    )}
  />
)

export default Snippet
