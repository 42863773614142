//React
import React from 'react'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../../components/layout'
import FeatureMenu from '../../components/marketing/featureMenu'
import FeatureTitle from '../../components/marketing/featureTitle'
import FeatureBody from '../../components/marketing/featureBody'

const Features = (props) => (
  <Layout>
    <Helmet
      title="Picohealth - Features - Automated Health Assessment"
      meta={[
        {
          name: 'description',
          content:
            'See features of the Picohealth automated health assessment platform - intuitive onboarding, alerts, reporting, and more',
        },
        {
          name: 'keywords',
          content:
            'mindfulness, meditation, stress, stress management, wellness, wellness challenges',
        },
      ]}
    />
    <div class="gradient-callout-cousteau">
      <div class="container container-page container-about-sub">
        <div class="row">
          <div class="col-lg-8 d-flex flex-column justify-content-center text-center text-lg-left">
            <h1 class="display-2 font-weight-medium white-1">Features</h1>
            <p class="display-4 white-4">
              Automated mobile health self-certifications to reduce your
              business risk and increase customer confidence
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container container-page container-features">
      <div class="row d-flex">
        <div class="col-feature-menu d-none d-md-block col-md-4">
          <FeatureMenu />
        </div>
        <div class="col-md-8">
          {/* BEGIN SECTION */}
          <FeatureTitle
            context="onboarding"
            title="Onboarding"
            color="purple"
          />
          <FeatureBody
            context="onboarding"
            imagealt="Easy and fun chatbot interface"
            direction="reverse"
            data={[
              {
                title: 'Text-to-start',
                body:
                  'Rapidly onboard users with text messaging and deliver their screening questionnaire link',
              },

              {
                title: 'No apps to install',
                body:
                  'Get your employees and customers started without barriers via SMS - no app download or install required',
              },
              // {
              //   title: 'Mobile & cross-platform',
              //   body:
              //     'Engage with users on their phones across platforms: iOS, Android, Windows, and popular browsers',
              // },
            ]}
          />

          <FeatureTitle
            context="certification"
            title="Self-certification"
            color="blue"
          />
          <FeatureBody
            context="certification"
            imagealt="Personalized video creation in seconds"
            data={[
              {
                title: 'Customizable questions',
                body:
                  'Use a standard set of questions to assess health history, or customize with your own questions',
              },

              {
                title: 'Brand customization',
                body:
                  'Customize the health assessment with your logo and brand identity',
              },
              {
                title: 'Mobile friendly',
                body:
                  'Engage users quickly and intuitively on their mobile phones, saving time before they visit your location',
              },
            ]}
          />

          <FeatureTitle
            context="evaluation"
            title="Response evaluation"
            color="sky"
          />
          <FeatureBody
            direction="reverse"
            context="evaluation"
            imagealt="Content moderation for brand-safe videos"
            data={[
              {
                title: 'Assessment criteria',
                body:
                  "Select criteria to which user responses should be compared, according to your organization's safety & health policies",
              },
              {
                title: 'User actions',
                body:
                  'Define next steps based on user responses, including: approving their visit or shift start, rescheduling an appointment, asking for more info, and more',
              },
            ]}
          />

          <FeatureTitle
            context="notifications"
            title="Alerts & notifications"
            color="green"
          />

          <FeatureBody
            context="notifications"
            imagealt="Delivery via text messaging"
            data={[
              {
                title: 'User notifications',
                body:
                  'Deliver next steps back to the employee or customer immediately in the browser, based on criteria you define',
              },
              // {
              //   title: 'Admin alerts',
              //   body:
              //     'Alert administrators of risky responses via proactive email or text messaging',
              // },
              {
                title: 'Response texting',
                body:
                  "Allow users to send in a keyword, and receive back your organization's health screening questionnaire",
              },
              {
                title: 'Proactive texting',
                body:
                  'Proactively send a health screener via SMS to employees before shift start, including support for different departments, locations, or divisions',
              },
            ]}
          />

          <FeatureTitle
            context="reporting"
            title="Reporting & audit"
            color="yellow"
          />

          <FeatureBody
            direction="reverse"
            context="reporting"
            imagealt="Social sharing capabilities"
            data={[
              {
                title: 'Reporting',
                body:
                  'View trends of responses that match your risk-based criteria ',
              },
              {
                title: 'Drill-down analysis',
                body:
                  'Automatically flag risky responses to examine, and follow-up for additional info',
              },
              {
                title: 'Audit log',
                body:
                  'Maintain a log of all certifications for risk management and compliance purposes',
              },
            ]}
          />

          <FeatureTitle
            context="security"
            title="Security & privacy"
            color="orange"
          />

          <FeatureBody
            context="security"
            imagealt="Analytics reporting"
            data={[
              {
                title: 'Security',
                body:
                  'Maintain security with encryption to protect data both in transit and at rest',
              },
              {
                title: 'Privacy',
                body:
                  'Protect user privacy with robust controls permitting access only to authorized admin users of your company',
              },
            ]}
          />

          {/* <FeatureTitle context="analytics" title="Analytics" color="purple" />
          <div class="feature-body d-flex flex-row align-items-center justify-content-start">
            <Img
              alt="Content moderation"
              style={{ minWidth: 240 }}
              fixed={props.data.featureModeration.childImageSharp.fixed}
            />
            <div class="feature-body">
              <ul>
                <li>Feature 1</li>
                <li>Feature 2</li>
                <li>Feature 3</li>
              </ul>
            </div>
          </div> */}
          {/* END SECTIONS */}
        </div>
      </div>
    </div>
  </Layout>
)

export default Features
