//React
import React from 'react'

//Icons
import { FaClipboardList } from 'react-icons/fa'
import { MdPhonelinkRing, MdLock } from 'react-icons/md'
import { IoIosRocket, IoMdBody } from 'react-icons/io'
import { AiFillSafetyCertificate, AiFillTool } from 'react-icons/ai'

const Snippet = props => (
  <div
    id={props.context}
    class={`feature-title bg-${props.color}-1 d-flex flex-row align-items-center justify-content-start`}
  >
    <div
      class={`feature-detail-icon-outer-container d-flex flex-column align-items-center justify-content-center bg-${props.color}-4`}
    >
      <div
        class={`feature-detail-icon-container d-flex flex-column align-items-center justify-content-center bg-${props.color}-1`}
      >
        {props.context === 'onboarding' ? (
          <IoIosRocket
            style={{
              color: '#ffffff',
              fontSize: 30,
              opacity: 0.8,
              strokeWidth: 1,
            }}
          />
        ) : null}
        {props.context === 'certification' ? (
          <AiFillSafetyCertificate
            style={{
              color: '#ffffff',
              fontSize: 30,
              opacity: 0.8,
              strokeWidth: 1,
            }}
          />
        ) : null}
        {props.context === 'evaluation' ? (
          <IoMdBody
            size={36}
            style={{
              color: '#ffffff',
              fontSize: 30,
              opacity: 0.8,
              strokeWidth: 1,
            }}
          />
        ) : null}
        {props.context === 'notifications' ? (
          <MdPhonelinkRing
            style={{
              color: '#ffffff',
              fontSize: 30,
              opacity: 0.8,
              strokeWidth: 1,
            }}
          />
        ) : null}
        {props.context === 'reporting' ? (
          <FaClipboardList
            style={{
              color: '#ffffff',
              fontSize: 30,
              opacity: 0.8,
              strokeWidth: 1,
            }}
          />
        ) : null}
        {props.context === 'security' ? (
          <MdLock
            size={30}
            style={{
              color: '#ffffff',
              fontSize: 30,
              opacity: 0.8,
              strokeWidth: 1,
            }}
          />
        ) : null}
      </div>
    </div>
    <span>{props.title}</span>
  </div>
)

export default Snippet
